import { gql } from '@apollo/client'
import { Enquete, CreateEnqueteInput } from 'types/generated/graphql'

export type CreateEnqueteData = {
  createEnquete: Enquete
}

export type CreateEnqueteVars = {
  input: CreateEnqueteInput
}

export const CREATE_ENQUETE = gql`
  mutation CreateEnquete($input: CreateEnqueteInput!) {
    createEnquete(input: $input) {
      id
      title
      question
      periodDisplayFlag
      numberOfVoteDisplayFlag
      showResult
      resultText
      allowDuplicateAnswer
      startDate
      deadline
      optionsCsv
      optionsImageUrls
      imageUrl
      published
      ownerUser {
        name
      }
      ownerGroup {
        name
      }
    }
  }
`
