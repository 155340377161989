import React, { useState, useCallback, useMemo } from 'react'
import { Formik, Form, Field, FieldProps } from 'formik'
import {
  Container,
  Box,
  Center,
  Flex,
  FormControl,
  Checkbox,
  Button,
  IconButton,
  Text,
  Grid,
} from '@chakra-ui/react'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import { Quiz, Stats, QuizDesignType } from 'types/generated/graphql'
import {
  quizFormSchema,
  QuizFormValues,
} from 'services/validators/QuizFormSchema'
import { QuizImageForm } from './QuizImageForm'
import { DesignTemplate } from './DesignTemplate'
import { FormInput } from 'components/Common/InputPageComponent/FormInput'
import { FormTextarea } from 'components/Common/InputPageComponent/FormTextarea'

type Props = {
  initialValues?: Quiz
  quizStats?: Stats
  submitButtonLabel: string
  imageUrl?: string
  onSubmit: (values: QuizFormValues) => void
}

export const QuizForm: React.FC<Props> = ({
  initialValues,
  quizStats,
  submitButtonLabel,
  onSubmit,
}) => {
  const getStatLabel = (index: number) => {
    if (!quizStats?.stats[index]) {
      return ''
    }
    return `　回答数${quizStats?.stats[index].count}件(${quizStats?.stats[index].percent})`
  }
  const [wrongs, setWrongs] = useState(() => {
    if (initialValues === undefined) {
      return [{ name: 'wrongs.0', label: '不正解1', placeholder: '不正解1' }]
    }
    return initialValues.content.wrongs.map((_, i) => ({
      name: `wrongs.${i}`,
      label: `不正解${i + 1}${getStatLabel(i + 1)}`,
      placeholder: `不正解${i + 1}`,
    }))
  })

  const [design, setDesign] = useState<QuizDesignType>(
    initialValues?.design || 'ORIGINAL'
  )

  const delWrong = useCallback(
    (values: QuizFormValues) => {
      wrongs.pop()
      setWrongs([...wrongs])
      values.wrongs.pop()
    },
    [wrongs, setWrongs]
  )

  const addWrong = useCallback(
    (values: QuizFormValues) => {
      setWrongs([
        ...wrongs,
        {
          name: `wrongs.${wrongs.length}`,
          label: `不正解${wrongs.length + 1}`,
          placeholder: `不正解${wrongs.length + 1}`,
        },
      ])
      values.wrongs.push('')
    },
    [wrongs, setWrongs]
  )

  const content = useMemo<QuizFormValues>(() => {
    if (!initialValues) {
      return quizFormSchema.getDefault()
    }

    return {
      question: initialValues.content.question,
      correct: initialValues.content.correct,
      wrongs: initialValues.content.wrongs.filter(Boolean),
      wrongImageUrls: initialValues.content.wrongImageUrls ?? [''],
      description: initialValues.content.description ?? '',
      snsShareText: initialValues.snsInfo.shareText ?? '',
      design: initialValues.design ?? '',
      destinationLink: initialValues.snsInfo.destinationLink ?? '',
      destinationLinkText: initialValues.snsInfo.destinationLinkText ?? '',
      published: initialValues.published,
      imageUrl: initialValues.content.imageUrl ?? '',
      correctImageUrl: initialValues.content.correctImageUrl ?? '',
      answerImageUrl: initialValues.content.answerImageUrl ?? '',
      bgImageUrl: initialValues.content.bgImageUrl ?? '',
    }
  }, [initialValues])

  return (
    <Formik
      initialValues={quizFormSchema.cast(content, {
        stripUnknown: true,
      })}
      validationSchema={quizFormSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, isSubmitting, isValid }) => {
        return (
          <Form>
            <Container maxW="container.md">
              <Flex direction="column">
                <QuizImageForm />
                <Box mb="1rem">
                  <FormTextarea name="question" label="問題文" isRequired />
                </Box>
                <Box mb="1rem">
                  {quizStats && (
                    <Text fontSize="sm" mb="1rem">
                      総回答数:{quizStats.total}件
                    </Text>
                  )}
                  <FormInput
                    name="correct"
                    label={'正解' + getStatLabel(0)}
                    placeholder="正解"
                    isRequired
                  />
                  {wrongs.map((wrong) => (
                    <FormInput
                      key={wrong.name}
                      name={wrong.name}
                      label={wrong.label}
                      placeholder={wrong.placeholder}
                      isRequired
                    />
                  ))}
                  <Center>
                    {wrongs.length <= 2 && (
                      <IconButton
                        colorScheme="red"
                        aria-label="不正解欄追加"
                        isRound
                        icon={<AddIcon />}
                        onClick={() => addWrong(values)}
                        mr={2}
                      />
                    )}
                    {wrongs.length >= 2 && (
                      <IconButton
                        colorScheme="blue"
                        aria-label="不正解欄削除"
                        isRound
                        icon={<MinusIcon />}
                        onClick={() => delWrong(values)}
                      />
                    )}
                  </Center>
                </Box>
                <Box mb="1rem">
                  <FormTextarea name="description" label="解説文(オプション)" />
                </Box>
                <Box mb="1rem">
                  <Text fontSize="xs" mb="0.5rem" fontWeight="500">
                    デザインテンプレート選択
                  </Text>
                  <Grid
                    templateRows={{
                      base: 'repeat(1, 1fr)',
                      sm: 'repeat(1, 1fr)',
                    }}
                    templateColumns={{
                      base: 'repeat(1, 1fr)',
                      sm: 'repeat(3, 1fr)',
                    }}
                    gap={4}
                  >
                    <DesignTemplate
                      design={design}
                      displayName="シンプル"
                      isSelected={design === 'SIMPLE'}
                      sampleImgUrl="/images/simple.png"
                      sampleImgAlt="simpleImage"
                      onSelect={() => setDesign('SIMPLE')}
                    />
                    <DesignTemplate
                      design={design}
                      displayName="シリアス"
                      isSelected={design === 'SERIOUS'}
                      sampleImgUrl="/images/serious.png"
                      sampleImgAlt="seriousImage"
                      onSelect={() => setDesign('SERIOUS')}
                    />
                    <DesignTemplate
                      design={design}
                      displayName="オリジナル"
                      isSelected={design === 'ORIGINAL'}
                      sampleImgUrl="/images/default.png"
                      sampleImgAlt="defaultImage"
                      onSelect={() => setDesign('ORIGINAL')}
                    />
                  </Grid>
                </Box>
                <Box mb={5}>
                  <FormInput name="snsShareText" label="SNS投稿文" />
                  <FormInput name="destinationLink" label="遷移先リンク" />
                  <FormInput
                    name="destinationLinkText"
                    label="遷移先リンクの説明"
                  />
                </Box>
                <Field name="published">
                  {({ form }: FieldProps) => (
                    <FormControl display="flex" alignItems="center" mb={4}>
                      <Checkbox
                        name="published"
                        id="published"
                        colorScheme="red"
                        defaultChecked={values.published}
                        onChange={() => {
                          form.setFieldValue('published', !values.published)
                        }}
                      >
                        公開する
                      </Checkbox>
                    </FormControl>
                  )}
                </Field>
                <Button
                  type="submit"
                  m="auto"
                  bgColor="#F3B0AE"
                  width="10rem"
                  isLoading={isSubmitting}
                  disabled={!isValid}
                >
                  {submitButtonLabel}
                </Button>
              </Flex>
            </Container>
          </Form>
        )
      }}
    </Formik>
  )
}
