import { boolean, object, string, array, TypeOf } from 'yup'

export const quizFormSchema = object({
  question: string()
    .required('問題文は必須です')
    .max(120, '文字数を120字以内にしてください。')
    .default(''),
  correct: string().required('正解は必須です').default(''),
  design: string().ensure().default('ORIGINAL'),
  wrongs: array()
    .required()
    .test(
      'requied_one',
      '間違いは1つ以上必要です',
      (values) => !!values?.some(Boolean)
    )
    .test(
      'required',
      '未入力の選択肢があります。入力するか未入力の選択肢を削除してください。',
      (values) => {
        if (
          Array.isArray(values) &&
          values.some((wrongs) => wrongs === undefined)
        ) {
          return false
        }
        return true
      }
    )
    .default(['']),
  wrongImageUrls: array().ensure().default(['']),
  imageUrl: string().ensure().default(''),
  correctImageUrl: string().ensure().default(''),
  answerImageUrl: string().ensure().default(''),
  bgImageUrl: string().ensure().default(''),
  description: string().ensure().default(''),
  snsShareText: string().ensure().default(''),
  destinationLink: string()
    .url('「遷移先リンク」はURL形式で入力してください。')
    .ensure()
    .default(''),
  destinationLinkText: string().ensure().default(''),
  published: boolean().default(false),
}).defined()

export type QuizFormValues = TypeOf<typeof quizFormSchema>
