import React, { useMemo } from 'react'
import {
  Stack,
  HStack,
  VStack,
  Text,
  Box,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from '@chakra-ui/react'
import type { Quiz } from 'types/generated/graphql'
import { FormTextarea } from 'v2components/Common/InputPageComponent/FormTextarea'
import { FormInput } from 'v2components/Common/InputPageComponent/FormInput'
import quzillaIcon from 'assets/images/quzilla-icon.png'

type Props = {
  selectedQuizzes: Quiz[]
  passingScore: number
  onSlidePassingScore: (passingScore: number) => void
}

export const BorderSetting: React.FC<Props> = ({
  selectedQuizzes,
  passingScore,
  onSlidePassingScore,
}) => {
  const publishedQuizCount = useMemo(
    () => selectedQuizzes.filter((quiz) => quiz?.published).length,
    [selectedQuizzes]
  )

  return (
    <Stack direction="column" spacing="24px">
      {publishedQuizCount ? (
        <>
          <Stack
            w="100%"
            borderWidth="1px"
            flex="1"
            color="#fff"
            borderRadius="16px"
            backgroundColor="#2B5080"
            pt="32px"
            pb="20px"
            px="20px"
            alignItems="center"
          >
            <Text
              fontSize="20px"
              fontWeight="700"
              lineHeight="1"
              letterSpacing="0.04em"
              textAlign="center"
              mb="24px"
            >
              合格ラインの設定
            </Text>
            <HStack
              fontWeight="500"
              alignItems="flex-end"
              justifyContent="center"
              lineHeight="1"
            >
              <Text fontSize="64px" color="#FFD000">
                {passingScore}
              </Text>
              <Text fontSize="40px" transform="translate(0, -9px)">
                /
              </Text>
              <Text fontSize="40px">{publishedQuizCount}</Text>
              <Text fontSize="28px">問</Text>
            </HStack>
            <Slider
              aria-label="slider-ex-4"
              defaultValue={passingScore}
              min={0}
              max={publishedQuizCount}
              step={1}
              onChangeEnd={onSlidePassingScore}
              justifyContent="center"
              w="60%"
            >
              <SliderTrack bg="#fff" h="8px" borderRadius="5px">
                <SliderFilledTrack bg="#EAF7FF" />
              </SliderTrack>
              <SliderThumb
                boxSize="70px"
                bg="none"
                boxShadow="none"
                border="0"
                _focus={{ boxShadow: 'none' }}
              >
                <Box
                  w="70px"
                  h="70px"
                  backgroundImage={quzillaIcon}
                  backgroundSize="contain"
                  backgroundRepeat="no-repeat"
                  borderRadius="50%"
                  boxShadow="0 0 12px rgba(0,0,0,.6)"
                />
              </SliderThumb>
            </Slider>
          </Stack>
          <FormTextarea
            name="passText"
            label="合格ライン以上"
            placeholder="例：よくできました！合格です！"
          />
          <FormInput
            name="passLink"
            label="合格ライン以上リンク"
            placeholder="例：https://example.com"
          />
          <FormInput
            name="passLinkText"
            label="合格ライン以上リンク表示文字"
            placeholder="例：おめでとうございます！"
          />
          <FormTextarea
            name="failText"
            label="合格ライン以下"
            placeholder="例：もう少し頑張りましょう…"
          />
          <FormInput
            name="failLink"
            label="合格ライン以下リンク"
            placeholder="例：https://example.com"
          />
          <FormInput
            name="failLinkText"
            label="合格ライン以下リンク表示文字"
            placeholder="例：もう一度解く…"
          />
        </>
      ) : (
        <VStack w="100%" borderWidth="1px" flex="1" borderRadius="md" p="50px">
          <Text fontSize="md">
            プレイリスト追加済のクイズに「公開」されているクイズがありません。
          </Text>
        </VStack>
      )}
    </Stack>
  )
}
