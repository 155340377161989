export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Time: any;
  Void: void;
};

export type AnswerEnqueteInput = {
  answer: Scalars['String'];
  enqueteId: Scalars['String'];
};

export type AnswerQuizInput = {
  answer: Scalars['String'];
  quizId: Scalars['String'];
};

export type CreateEnqueteInput = {
  allowDuplicateAnswer: Scalars['Boolean'];
  deadline?: InputMaybe<Scalars['Time']>;
  displayUnit: DisplayUnit | `${DisplayUnit}`;
  id?: InputMaybe<Scalars['ID']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  numberOfVoteDisplayFlag: Scalars['Boolean'];
  optionsCsv: Scalars['String'];
  optionsImageUrls?: InputMaybe<Scalars['String']>;
  ownerGroupId?: InputMaybe<Scalars['ID']>;
  periodDisplayFlag: Scalars['Boolean'];
  published: Scalars['Boolean'];
  question: Scalars['String'];
  resultText?: InputMaybe<Scalars['String']>;
  showResult: Scalars['Boolean'];
  startDate?: InputMaybe<Scalars['Time']>;
  title: Scalars['String'];
};

export type CreateGroupInput = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type CreatePlaylistInput = {
  description?: InputMaybe<Scalars['String']>;
  design?: InputMaybe<ListDesignType | `${ListDesignType}`>;
  failLink?: InputMaybe<Scalars['String']>;
  failLinkText?: InputMaybe<Scalars['String']>;
  failText?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  ownerGroupId?: InputMaybe<Scalars['ID']>;
  passLink?: InputMaybe<Scalars['String']>;
  passLinkText?: InputMaybe<Scalars['String']>;
  passText?: InputMaybe<Scalars['String']>;
  passingScore?: InputMaybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  quizIds: Array<Scalars['ID']>;
  snsInfo?: InputMaybe<SnsInfoInput>;
};

export type CreateQuizInput = {
  answerImageUrl?: InputMaybe<Scalars['String']>;
  bgImageUrl?: InputMaybe<Scalars['String']>;
  correct: Scalars['String'];
  correctImageUrl?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  design?: InputMaybe<QuizDesignType | `${QuizDesignType}`>;
  id?: InputMaybe<Scalars['ID']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  ownerGroupId?: InputMaybe<Scalars['ID']>;
  published: Scalars['Boolean'];
  question: Scalars['String'];
  snsInfo?: InputMaybe<SnsInfoInput>;
  title?: InputMaybe<Scalars['String']>;
  wrongImageUrls?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  wrongs: Array<Scalars['String']>;
};

export type CreateUserInput = {
  aliasId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['Time']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Time']>;
};

export const DisplayUnit = {
  Both: 'BOTH',
  Percentage: 'PERCENTAGE',
  Vote: 'VOTE'
} as const;

export type DisplayUnit = typeof DisplayUnit[keyof typeof DisplayUnit];
export type Enquete = {
  __typename?: 'Enquete';
  allowDuplicateAnswer: Scalars['Boolean'];
  answers?: Maybe<Array<Maybe<EnqueteAnswer>>>;
  createdAt: Scalars['Time'];
  deadline?: Maybe<Scalars['Time']>;
  displayUnit: DisplayUnit | `${DisplayUnit}`;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  numberOfVoteDisplayFlag: Scalars['Boolean'];
  optionsCsv: Scalars['String'];
  optionsImageUrls?: Maybe<Scalars['String']>;
  ownerGroup?: Maybe<GroupWithoutMembers>;
  ownerUser?: Maybe<User>;
  periodDisplayFlag: Scalars['Boolean'];
  published: Scalars['Boolean'];
  question: Scalars['String'];
  resultText?: Maybe<Scalars['String']>;
  selectMax: Scalars['Int'];
  selectMin: Scalars['Int'];
  showResult: Scalars['Boolean'];
  startDate?: Maybe<Scalars['Time']>;
  title: Scalars['String'];
  updateUser?: Maybe<User>;
  updatedAt: Scalars['Time'];
};

export type EnqueteAnswer = {
  __typename?: 'EnqueteAnswer';
  answer?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  enqueteId: Scalars['ID'];
};

export type EnquetePub = {
  __typename?: 'EnquetePub';
  id: Scalars['ID'];
  published: Scalars['Boolean'];
};

export type EnqueteStats = {
  __typename?: 'EnqueteStats';
  enqueteId: Scalars['ID'];
  stats: Array<StatsPerSelection>;
  total: Scalars['Int'];
};

export type Group = {
  __typename?: 'Group';
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  members: Array<Member>;
  name: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
};

export type GroupMemberInput = {
  email: Scalars['String'];
  role: Role | `${Role}`;
};

export type GroupWithoutMembers = {
  __typename?: 'GroupWithoutMembers';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export const ListDesignType = {
  None: 'NONE',
  Original: 'ORIGINAL',
  Serious: 'SERIOUS',
  Simple: 'SIMPLE'
} as const;

export type ListDesignType = typeof ListDesignType[keyof typeof ListDesignType];
export type Member = {
  __typename?: 'Member';
  role: Role | `${Role}`;
  user: User;
};

export type Mutation = {
  __typename?: 'Mutation';
  addMember?: Maybe<Scalars['Void']>;
  answerEnquete?: Maybe<Scalars['Void']>;
  answerQuiz?: Maybe<Scalars['Void']>;
  createEnquete?: Maybe<Enquete>;
  createGroup?: Maybe<Group>;
  createPlaylist?: Maybe<Playlist>;
  createQuiz?: Maybe<Quiz>;
  createUser?: Maybe<User>;
  deleteEnquete?: Maybe<Scalars['Void']>;
  deleteGroup?: Maybe<Scalars['Void']>;
  deleteMember?: Maybe<Scalars['Void']>;
  deletePlaylist?: Maybe<Scalars['Void']>;
  deleteQuiz?: Maybe<Scalars['Void']>;
  deleteUser?: Maybe<Scalars['Void']>;
  updateEnquete?: Maybe<Enquete>;
  updateEnquetePub?: Maybe<EnquetePub>;
  updateGroup?: Maybe<Group>;
  updateMember?: Maybe<Scalars['Void']>;
  updatePlaylist?: Maybe<Playlist>;
  updatePlaylistPub?: Maybe<PlaylistPub>;
  updateQuiz?: Maybe<Quiz>;
  updateQuizPub?: Maybe<QuizPub>;
  updateUser?: Maybe<User>;
};


export type MutationAddMemberArgs = {
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationAnswerEnqueteArgs = {
  input: AnswerEnqueteInput;
};


export type MutationAnswerQuizArgs = {
  input: AnswerQuizInput;
};


export type MutationCreateEnqueteArgs = {
  input: CreateEnqueteInput;
};


export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};


export type MutationCreatePlaylistArgs = {
  input: CreatePlaylistInput;
};


export type MutationCreateQuizArgs = {
  input: CreateQuizInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteEnqueteArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteGroupArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMemberArgs = {
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationDeletePlaylistArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteQuizArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateEnqueteArgs = {
  input: UpdateEnqueteInput;
};


export type MutationUpdateEnquetePubArgs = {
  input: UpdateEnqueteInputPub;
};


export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};


export type MutationUpdateMemberArgs = {
  groupId: Scalars['ID'];
  role: Role;
  userId: Scalars['ID'];
};


export type MutationUpdatePlaylistArgs = {
  input: UpdatePlaylistInput;
};


export type MutationUpdatePlaylistPubArgs = {
  input: UpdatePlaylistInputPub;
};


export type MutationUpdateQuizArgs = {
  input: UpdateQuizInput;
};


export type MutationUpdateQuizPubArgs = {
  input: UpdateQuizInputPub;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MyPage = {
  __typename?: 'MyPage';
  enquetes: Array<Enquete>;
  playlists: Array<Playlist>;
  quizzes: Array<Quiz>;
};

export type Playlist = {
  __typename?: 'Playlist';
  createdAt: Scalars['Time'];
  description?: Maybe<Scalars['String']>;
  design?: Maybe<ListDesignType | `${ListDesignType}`>;
  failLink?: Maybe<Scalars['String']>;
  failLinkText?: Maybe<Scalars['String']>;
  failText?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ownerGroup?: Maybe<GroupWithoutMembers>;
  ownerUser?: Maybe<User>;
  passLink?: Maybe<Scalars['String']>;
  passLinkText?: Maybe<Scalars['String']>;
  passText?: Maybe<Scalars['String']>;
  passingScore?: Maybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  quizzes: Array<Quiz>;
  snsInfo: SnsInfo;
  updateUser?: Maybe<User>;
  updatedAt: Scalars['Time'];
};

export type PlaylistPub = {
  __typename?: 'PlaylistPub';
  id: Scalars['ID'];
  published: Scalars['Boolean'];
};

export type PlaylistsFilter = {
  ownerGroupId?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  enquete?: Maybe<Enquete>;
  enqueteStats: EnqueteStats;
  enquetes: Array<Enquete>;
  group?: Maybe<Group>;
  myPage: MyPage;
  playlist?: Maybe<Playlist>;
  playlistStats: Array<Maybe<Stats>>;
  playlists: Array<Playlist>;
  quiz?: Maybe<Quiz>;
  quizStats: Stats;
  quizzes: Array<Quiz>;
  user?: Maybe<User>;
  users: Array<User>;
};


export type QueryEnqueteArgs = {
  id: Scalars['ID'];
};


export type QueryEnqueteStatsArgs = {
  enqueteId: Scalars['ID'];
};


export type QueryEnquetesArgs = {
  groupId?: InputMaybe<Scalars['ID']>;
};


export type QueryGroupArgs = {
  id: Scalars['ID'];
};


export type QueryMyPageArgs = {
  groupId?: InputMaybe<Scalars['ID']>;
};


export type QueryPlaylistArgs = {
  id: Scalars['ID'];
  onlyPublishedQuizzes?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPlaylistStatsArgs = {
  playlistId: Scalars['ID'];
};


export type QueryPlaylistsArgs = {
  filter?: InputMaybe<PlaylistsFilter>;
};


export type QueryQuizArgs = {
  id: Scalars['ID'];
};


export type QueryQuizStatsArgs = {
  quizId: Scalars['ID'];
};


export type QueryQuizzesArgs = {
  filter?: InputMaybe<QuizzesFilter>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type Quiz = {
  __typename?: 'Quiz';
  answers?: Maybe<Array<Maybe<QuizAnswer>>>;
  content: QuizContent;
  createdAt: Scalars['Time'];
  design?: Maybe<QuizDesignType | `${QuizDesignType}`>;
  id: Scalars['ID'];
  ownerGroup?: Maybe<GroupWithoutMembers>;
  ownerUser?: Maybe<User>;
  published: Scalars['Boolean'];
  snsInfo: SnsInfo;
  title?: Maybe<Scalars['String']>;
  updateUser?: Maybe<User>;
  updatedAt: Scalars['Time'];
};

export type QuizAnswer = {
  __typename?: 'QuizAnswer';
  answer?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  quizId: Scalars['ID'];
};

export type QuizContent = {
  __typename?: 'QuizContent';
  answerImageUrl?: Maybe<Scalars['String']>;
  bgImageUrl?: Maybe<Scalars['String']>;
  correct: Scalars['String'];
  correctImageUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  question: Scalars['String'];
  wrongImageUrls?: Maybe<Array<Maybe<Scalars['String']>>>;
  wrongs: Array<Scalars['String']>;
};

export const QuizDesignType = {
  None: 'NONE',
  Original: 'ORIGINAL',
  Serious: 'SERIOUS',
  Simple: 'SIMPLE'
} as const;

export type QuizDesignType = typeof QuizDesignType[keyof typeof QuizDesignType];
export type QuizPub = {
  __typename?: 'QuizPub';
  id: Scalars['ID'];
  published: Scalars['Boolean'];
};

export type QuizzesFilter = {
  ownerGroupId?: InputMaybe<Scalars['String']>;
};

export const Role = {
  Master: 'MASTER',
  Member: 'MEMBER',
  Owner: 'OWNER'
} as const;

export type Role = typeof Role[keyof typeof Role];
export type SnsInfo = {
  __typename?: 'SnsInfo';
  destinationLink?: Maybe<Scalars['String']>;
  destinationLinkText?: Maybe<Scalars['String']>;
  ogpImageUrl?: Maybe<Scalars['String']>;
  shareText?: Maybe<Scalars['String']>;
};

export type SnsInfoInput = {
  destinationLink?: InputMaybe<Scalars['String']>;
  destinationLinkText?: InputMaybe<Scalars['String']>;
  ogpImageUrl?: InputMaybe<Scalars['String']>;
  shareText?: InputMaybe<Scalars['String']>;
};

export type Stats = {
  __typename?: 'Stats';
  quizId: Scalars['ID'];
  stats: Array<StatsPerSelection>;
  total: Scalars['Int'];
};

export type StatsPerSelection = {
  __typename?: 'StatsPerSelection';
  count: Scalars['Int'];
  percent: Scalars['String'];
  selection: Scalars['String'];
};

export type UpdateEnqueteInput = {
  allowDuplicateAnswer: Scalars['Boolean'];
  deadline?: InputMaybe<Scalars['Time']>;
  displayUnit: DisplayUnit | `${DisplayUnit}`;
  id: Scalars['ID'];
  imageUrl?: InputMaybe<Scalars['String']>;
  numberOfVoteDisplayFlag: Scalars['Boolean'];
  optionsCsv?: InputMaybe<Scalars['String']>;
  optionsImageUrls?: InputMaybe<Scalars['String']>;
  periodDisplayFlag: Scalars['Boolean'];
  published: Scalars['Boolean'];
  question?: InputMaybe<Scalars['String']>;
  resultText?: InputMaybe<Scalars['String']>;
  showResult: Scalars['Boolean'];
  startDate?: InputMaybe<Scalars['Time']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateEnqueteInputPub = {
  id: Scalars['ID'];
  published: Scalars['Boolean'];
};

export type UpdateGroupInput = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdatePlaylistInput = {
  description?: InputMaybe<Scalars['String']>;
  design?: InputMaybe<ListDesignType | `${ListDesignType}`>;
  failLink?: InputMaybe<Scalars['String']>;
  failLinkText?: InputMaybe<Scalars['String']>;
  failText?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  ownerGroupId?: InputMaybe<Scalars['ID']>;
  passLink?: InputMaybe<Scalars['String']>;
  passLinkText?: InputMaybe<Scalars['String']>;
  passText?: InputMaybe<Scalars['String']>;
  passingScore?: InputMaybe<Scalars['Int']>;
  published?: InputMaybe<Scalars['Boolean']>;
  quizIds?: InputMaybe<Array<Scalars['ID']>>;
  snsInfo?: InputMaybe<SnsInfoInput>;
};

export type UpdatePlaylistInputPub = {
  id: Scalars['ID'];
  published: Scalars['Boolean'];
};

export type UpdateQuizInput = {
  answerImageUrl?: InputMaybe<Scalars['String']>;
  bgImageUrl?: InputMaybe<Scalars['String']>;
  correct?: InputMaybe<Scalars['String']>;
  correctImageUrl?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  design?: InputMaybe<QuizDesignType | `${QuizDesignType}`>;
  id: Scalars['ID'];
  imageUrl?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  question?: InputMaybe<Scalars['String']>;
  snsInfo?: InputMaybe<SnsInfoInput>;
  title?: InputMaybe<Scalars['String']>;
  wrongImageUrls?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  wrongs?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateQuizInputPub = {
  id: Scalars['ID'];
  published: Scalars['Boolean'];
};

export type UpdateUserInput = {
  aliasId?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Time']>;
};

export type User = {
  __typename?: 'User';
  aliasId: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Time']>;
  email: Scalars['String'];
  groups?: Maybe<Array<Group>>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Time']>;
};
