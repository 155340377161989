import React, { useState, useEffect } from 'react'
import {
  Text,
  Button,
  Box,
  VStack,
  Heading,
  Flex,
  SimpleGrid,
  Image,
  Stack,
  Center,
} from '@chakra-ui/react'
import { Quiz, Stats } from 'types/generated/graphql'
import { LayoutEmbedQuiz } from 'components/Common/LayoutEmbedQuiz'

export interface QuizProps {
  quiz: Quiz
  quizOptions: string[]
  imageUrls?: string[]
  quizStats?: Stats
  onNext: () => void
  onSelectedChoice: (selectedChoice: string) => void
}

export const MultipleChoiceEmbedPlayQuestion: React.FC<QuizProps> = ({
  quiz,
  quizOptions,
  imageUrls,
  quizStats,
  onNext,
  onSelectedChoice,
}) => {
  const [isImageMode, setIsImageMode] = useState(false)

  useEffect(() => {
    if (!imageUrls) {
      setIsImageMode(false)
      return
    }
    const hasNonEmptyString = imageUrls.some((item) => item !== '')
    setIsImageMode(hasNonEmptyString)
  }, [imageUrls])

  const quizChoicesContent = (
    <>
      {quizOptions.map((quiz, index) => {
        if (!quiz) {
          return null
        }
        return (
          <Box key={quiz + index}>
            <Button
              color="#333"
              bg="#fff"
              border="2px #333 solid"
              cursor="pointer"
              p="4px .6em"
              appearance="none"
              borderRadius="6px"
              fontSize="16px"
              fontWeight="700"
              lineHeight="1.6em"
              w="100%"
              h="100%"
              minH="40px"
              boxSizing="border-box"
              textAlign="left"
              justifyContent="initial"
              whiteSpace="pre-line"
              onClick={() => {
                onNext()
                onSelectedChoice(quiz)
              }}
              display="block"
            >
              {imageUrls && imageUrls[index] && (
                <Flex alignItems="center" mb="10px">
                  <Image
                    src={imageUrls[index]}
                    alt={`Quiz image ${index}`}
                    h="170px"
                    w="100%"
                    objectFit="contain"
                  />
                </Flex>
              )}

              <Flex alignItems="center">
                <Text
                  display="inline-block"
                  w="2.2em"
                  minW="2.2em"
                  h="2.2em"
                  lineHeight="2.2em"
                  borderRadius="50%"
                  fontSize="60%"
                  mr=".4em"
                  mt=".1em"
                  color="#fff"
                  bg="#333"
                  boxSizing="border-box"
                  textShadow="none"
                  textAlign="center"
                  verticalAlign="top"
                  as="span"
                >
                  {index + 1}
                </Text>
                <Text>{quiz}</Text>
              </Flex>
            </Button>
          </Box>
        )
      })}
    </>
  )

  return (
    <LayoutEmbedQuiz quiz={quiz} imageUrl={quiz.content.bgImageUrl!}>
      <VStack w="100%" spacing={3}>
        {quiz.content.imageUrl && (
          <Center
            h="390px"
            bgImage={`url(${quiz.content.imageUrl})`}
            bgSize="cover"
            bgPosition="center"
            bgRepeat="no-repeat"
            position="relative"
            borderRadius="20px"
            overflow="hidden"
            _before={{
              content: `""`,
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              bg: 'rgba(0, 0, 0, 0.35)',
              backdropFilter: 'blur(10px)',
              zIndex: 0,
              borderRadius: '20px',
              overflow: 'hidden',
            }}
            _after={{
              content: `""`,
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 1,
              borderRadius: '20px',
              overflow: 'hidden',
              border: '7px solid #fff',
            }}
          >
            <Image
              h="390px"
              w="100%"
              objectFit="contain"
              src={quiz.content.imageUrl}
              zIndex="1"
            />
          </Center>
        )}
        <Heading
          fontSize="18px"
          display="flex"
          fontWeight="800"
          textShadow="0 0 5px rgba(0, 0, 0, 0.5)"
          lineHeight="1.6em"
          m="0 0 0.3em"
          minWidth="100%"
          _before={{
            content: '"Q"',
            display: 'inline-block',
            textShadow: 'none',
            w: '1.8em',
            minWidth: '1.8em',
            h: '1.8em',
            lineHeight: '1.5em',
            borderRadius: '6px',
            fontSize: '70%',
            fontWeight: '800',
            mr: '.3em',
            mt: '.25em',
            bg: '#ffaf00',
            color: '#fff',
            textAlign: 'center',
          }}
        >
          {quiz.content.question}
          {quizStats &&
            // statsには[正解、不正解1、不正解2…]の順番で入る
            `(正答率${quizStats.stats[0].percent})`}
        </Heading>
        {isImageMode ? (
          <SimpleGrid columns={2} spacing="8px">
            {quizChoicesContent}
          </SimpleGrid>
        ) : (
          <Stack spacing="8px" w="100%">
            {quizChoicesContent}
          </Stack>
        )}
      </VStack>
    </LayoutEmbedQuiz>
  )
}
