import React from 'react'
import { Icon } from '@chakra-ui/react'
import { MdOutlineAddPhotoAlternate } from 'react-icons/md'
import { MdCloudUpload, MdOutlineHideImage } from 'react-icons/md'
import { IconType } from 'react-icons'

interface Props {
  imageUrl: string
  isDragAccept: boolean
  isDragReject: boolean
  type?: 'MIN'
}

export const StatusIcon: React.FC<Props> = ({
  imageUrl,
  isDragAccept,
  isDragReject,
  type,
}) => {
  const getIconType = (): IconType => {
    if (isDragAccept) return MdCloudUpload
    if (isDragReject) return MdOutlineHideImage

    return MdOutlineAddPhotoAlternate
  }

  return (
    <Icon
      as={getIconType()}
      w={type !== 'MIN' ? '72px' : '24px'}
      h={type !== 'MIN' ? '72px' : '24px'}
      ml={type !== 'MIN' ? '0px' : '4px'}
      mt={type !== 'MIN' ? '0px' : '4px'}
      color={(() => {
        if (isDragReject) {
          return '#EB5757'
        } else if (imageUrl) {
          return '#whiteAlpha.700'
        } else {
          return '#2B5080'
        }
      })()}
    />
  )
}
