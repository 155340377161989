import { gql } from '@apollo/client'
import { Quiz, CreateQuizInput } from 'types/generated/graphql'

export type CreateQuizData = {
  createQuiz: Quiz
}

export type CreateQuizVars = {
  input: CreateQuizInput
}

export const CREATE_QUIZ = gql`
  mutation CreateQuiz($input: CreateQuizInput!) {
    createQuiz(input: $input) {
      id
      title
      ownerUser {
        name
      }
      ownerGroup {
        name
      }
      published
      snsInfo {
        destinationLink
      }
      design
      content {
        question
        correct
        correctImageUrl
        wrongs
        wrongImageUrls
        description
        imageUrl
        answerImageUrl
        bgImageUrl
      }
    }
  }
`
