import { useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Grid,
  Input,
  Image,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react'
import { Field, FieldProps, Form, Formik } from 'formik'
import { useQuery } from '@apollo/client'
import { USER, UserData, UserVars } from 'graphql/apollo/query/user'
import { Layout } from 'v2components/Common/Layout'
import { SettingLayout } from 'v2components/Common/SettingLayout'
import { FormInput } from 'v2components/Common/InputPageComponents/FormInput'
import { FormTextarea } from 'v2components/Common/InputPageComponents/FormTextarea'
import { InfoModal } from 'v2components/Modal/InfoModal'
import { AlertModal } from 'v2components/Modal/AlertModal'
import { NoData } from 'components/Common/NoData'
import { useUserProfileContext } from 'state/userProfile'
import { useEditUser } from 'hooks/useEditUser'
import { userProfileFormSchema } from 'services/validators/UserProfileFormSchema'
import { fileToDataUrl } from 'utils/fileToDataUrl'
import { BiImageAdd } from 'react-icons/bi'
import { FaUserCog } from 'react-icons/fa'
import { Loading } from 'v2components/Common/Loading'

type Props = RouteComponentProps

export const MyPage: React.FC<Props> = () => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null)
  const {
    isOpen: isOpenInfoModal,
    onOpen: onOpenInfoModal,
    onClose: onCloseInfoModal,
  } = useDisclosure()
  const {
    isOpen: isOpenAlertModal,
    onOpen: onOpenAlertModal,
    onClose: onCloseAlertModal,
  } = useDisclosure()
  const { userProfile } = useUserProfileContext()
  const userId = userProfile?.user.id!
  const { handleEditUser } = useEditUser({ userProfile: userProfile })
  const { loading, data } = useQuery<UserData, UserVars>(USER, {
    variables: {
      id: userId,
    },
  })
  if (loading) return <Loading />
  if (!data) return <NoData />
  const initialValues = {
    name: data.user.name,
    aliasId: data.user.aliasId,
    comment: data.user.comment,
    icon: data.user.icon,
  }
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onloadend = () => {
          setSelectedImage(reader.result as string)
        }
        reader.readAsDataURL(file)
      }
    }
  }
  return (
    <Layout pageTitle="ユーザー設定">
      <SettingLayout>
        <Formik
          initialValues={userProfileFormSchema.cast(initialValues, {
            stripUnknown: true,
          })}
          validationSchema={userProfileFormSchema}
          onSubmit={async (values) => {
            try {
              await handleEditUser(values)
              onOpenInfoModal()
            } catch (e) {
              onOpenAlertModal()
              console.log(e)
            }
          }}
        >
          {({ isSubmitting, isValid }) => (
            <Form>
              <Flex flexDir="column" p="24px 20px">
                <Flex flexWrap="wrap" alignItems="flex-start" mb="16px">
                  <FaUserCog size={32} color="#2B5080" />
                  <Flex
                    color="#2B5080"
                    fontSize="24px"
                    fontWeight="500"
                    letterSpacing="0.04em"
                    pl="8px"
                    display="inline-flex"
                    alignItems="center"
                  >
                    ユーザー設定
                  </Flex>
                </Flex>
                <Flex gap="20px">
                  <Box
                    flexGrow={0}
                    flexShrink={0}
                    pt="16px"
                    pl="40px"
                    pr="40px"
                  >
                    <FormLabel borderRadius="50%" htmlFor="fileInput">
                      {selectedImage ? (
                        <Image
                          boxSize="200px"
                          objectFit="cover"
                          src={selectedImage}
                          alt="Uploaded Image"
                          borderRadius="full"
                          cursor="pointer"
                          border="1px dashed #2B5080"
                        />
                      ) : data.user.icon ? (
                        <Image
                          boxSize="200px"
                          objectFit="cover"
                          src={data.user.icon}
                          alt="Uploaded Image"
                          borderRadius="full"
                          cursor="pointer"
                          border="1px dashed #2B5080"
                        />
                      ) : (
                        <Flex
                          borderRadius="50%"
                          boxSizing="border-box"
                          w="200px"
                          h="200px"
                          backgroundColor="#EAF7FF"
                          border="1px dashed #2B5080"
                          cursor="pointer"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <BiImageAdd size={72} color="#2B5080" />
                        </Flex>
                      )}
                      <Field name="icon">
                        {({ form }: FieldProps) => (
                          <Box>
                            <Input
                              name="icon"
                              id="fileInput"
                              type="file"
                              accept="image/jpeg, image/png, image/gif, image/webp"
                              style={{ display: 'none' }}
                              onChange={async (event) => {
                                if (!!event.target.files![0]) {
                                  const imageDataUrl = await fileToDataUrl(
                                    event.target.files![0]
                                  )
                                  form.setFieldValue('icon', imageDataUrl)
                                  handleFileChange(event)
                                }
                              }}
                            />
                          </Box>
                        )}
                      </Field>
                    </FormLabel>
                  </Box>
                  <Flex flexGrow={1} flexDirection="column" gap="20px">
                    <Box>
                      <Flex
                        color="#222"
                        fontSize="14px"
                        fontWeight={500}
                        lineHeight="1.7142857143"
                        letterSpacing="0.04em"
                        alignItems="center"
                        mb="8px"
                      >
                        メールアドレス
                      </Flex>
                      <Box pt="8px" pb="4px" pl="16px" pr="16px">
                        {data.user.email}
                      </Box>
                    </Box>
                    <Box>
                      <FormInput
                        name="aliasId"
                        label="ユーザーID"
                        isRequired={true}
                      />
                    </Box>
                    <Box>
                      <FormInput
                        name="name"
                        label="ユーザーネーム"
                        isRequired={true}
                      />
                    </Box>
                    <Box>
                      <FormTextarea name="comment" label="コメント" />
                    </Box>
                  </Flex>
                </Flex>
                <Grid
                  mt="32px"
                  justifyContent="center"
                  gap="0 14px"
                  gridTemplateColumns="repeat(auto-fit, 160px)"
                >
                  <Button
                    color="#FFF"
                    fontSize="14px"
                    fontWeight={500}
                    lineHeight="1.7142857143"
                    letterSpacing="0.08em"
                    textAlign="center"
                    backgroundColor="#007BC2"
                    borderRadius="20px"
                    cursor="pointer"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    boxSizing="border-box"
                    p="8px 16px"
                    transitionDuration="0.5s"
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    _hover={{
                      bg: 'buttonPrimaryHover',
                    }}
                  >
                    {!isSubmitting ? '更新する' : <Spinner />}
                  </Button>
                </Grid>
              </Flex>
            </Form>
          )}
        </Formik>
        <InfoModal
          isOpen={isOpenInfoModal}
          onClose={onCloseInfoModal}
          modalBody="更新が完了しました"
        />
        <AlertModal
          isOpen={isOpenAlertModal}
          onClose={onCloseAlertModal}
          modalBody="更新処理が失敗しました"
        />
      </SettingLayout>
    </Layout>
  )
}
