import React, { useMemo } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { ImageComponent } from 'v2components/ImageUpload/ImageComponent'
import { IconComponent } from 'v2components/ImageUpload/IconComponent'
import { CoverComponent } from 'v2components/ImageUpload/CoverComponent'

const activeStyle = {
  border: '2px',
  borderColor: '#2B5080',
  borderStyle: 'dashed',
}

const acceptStyle = {
  border: '2px',
  borderColor: '#2B5080',
  borderStyle: 'dashed',
}

const rejectStyle = {
  border: '2px',
  borderColor: '#EB5757',
  borderStyle: 'dashed',
}

export type ImageType = 'NORMAL' | 'ICON' | 'COVER' | 'MIN'
type ContainerProps = {
  onDrop: DropzoneOptions['onDrop']
  imageUrl: string
  type?: ImageType
}

export const ImageUpload: React.FC<ContainerProps> = ({
  onDrop,
  imageUrl,
  type = 'NORMAL',
}) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: 'image/jpeg, image/png, image/gif, image/webp',
    onDrop,
    maxFiles: 1,
  })

  const style = useMemo(() => {
    return {
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }
  }, [isDragActive, isDragReject, isDragAccept])

  const imageArea = () => {
    switch (type) {
      case 'ICON':
        return (
          <IconComponent
            imageUrl={imageUrl}
            style={getRootProps({ style })}
            isDragAccept={isDragAccept}
            isDragReject={isDragReject}
          />
        )
      case 'COVER':
        return (
          <CoverComponent
            imageUrl={imageUrl}
            style={getRootProps({ style })}
            isDragAccept={isDragAccept}
            isDragReject={isDragReject}
          />
        )
      case 'NORMAL':
        return (
          <ImageComponent
            imageUrl={imageUrl}
            style={getRootProps({ style })}
            isDragAccept={isDragAccept}
            isDragReject={isDragReject}
          />
        )
      case 'MIN':
        return (
          <ImageComponent
            imageUrl={imageUrl}
            type={type}
            style={getRootProps({ style })}
            isDragAccept={isDragAccept}
            isDragReject={isDragReject}
          />
        )
    }
  }

  return (
    <>
      <input {...getInputProps()} />
      {imageArea()}
    </>
  )
}
