import { useCallback } from 'react'
import dayjs from 'dayjs'
import { useMutation } from '@apollo/client'
import { EnqueteFormValues } from 'services/validators/EnqueteFormSchema'
import { useUploadFile } from 'hooks/useUploadFile'
import {
  UpdateEnqueteData,
  UpdateEnqueteVars,
  UPDATE_ENQUETE,
} from 'graphql/apollo/mutation/enquete/updateEnquete'
import {
  UpdateEnqueteDataPub,
  UpdateEnqueteVarsPub,
  UPDATE_ENQUETE_PUB,
} from 'graphql/apollo/mutation/enquete/updateEnquetePub'

import { ENQUETE } from 'graphql/apollo/query/enquete'
import { ENQUETES } from 'graphql/apollo/query/enquetes'

type HooksArgs = {
  id: string
}

export const useUpdateEnquete = ({ id }: HooksArgs) => {
  const { handleUploadDataUrlImage } = useUploadFile()

  const [updateEnquete] = useMutation<UpdateEnqueteData, UpdateEnqueteVars>(
    UPDATE_ENQUETE
  )
  const [updateEnquetePub] = useMutation<
    UpdateEnqueteDataPub,
    UpdateEnqueteVarsPub
  >(UPDATE_ENQUETE_PUB)

  const handleUpdateEnquete = useCallback(
    async (values: EnqueteFormValues) => {
      const s3ImageUrl = await handleUploadDataUrlImage(
        values.imageUrl,
        'enquete'
      )

      const uploadOptionsImageUrls = async () => {
        const s3ImageUrls = []

        try {
          for (const imageUrl of values.optionsImageUrls) {
            if (imageUrl === '') {
              s3ImageUrls.push(imageUrl)
              continue
            }

            try {
              const s3ImageUrl = await handleUploadDataUrlImage(
                imageUrl,
                'enquete-option'
              )
              s3ImageUrls.push(s3ImageUrl)
            } catch (error) {
              console.error(`Error uploading image for ${imageUrl}:`, error)
              s3ImageUrls.push('')
            }
          }

          return s3ImageUrls
        } catch (error) {
          console.error('Error uploading images:', error)
          return []
        }
      }

      const s3OptionsImageUrls = await uploadOptionsImageUrls()

      const variables = {
        input: {
          id,
          optionsCsv: values.optionsCsv.join(','),
          optionsImageUrls: s3OptionsImageUrls.join(','),
          title: values.title,
          imageUrl: s3ImageUrl,
          published: values.published,
          question: values.question,
          periodDisplayFlag: values.periodDisplayFlag,
          numberOfVoteDisplayFlag: values.numberOfVoteDisplayFlag,
          showResult: values.showResult,
          resultText: values.resultText,
          allowDuplicateAnswer: values.allowDuplicateAnswer,
          startDate: values.startDate
            ? new Date(dayjs(values.startDate).format('YYYY/MM/DD HH:mm'))
            : undefined,
          deadline: values.deadline
            ? new Date(dayjs(values.deadline).format('YYYY/MM/DD HH:mm'))
            : undefined,
          displayUnit: values.displayUnit,
        },
      }
      await updateEnquete({
        variables: variables,
        refetchQueries: [ENQUETE, ENQUETES],
      })
    },
    [handleUploadDataUrlImage, updateEnquete, id]
  )

  const handleUpdateEnquetePub = useCallback(
    async (value: boolean) => {
      const variables = {
        input: {
          id,
          published: value,
        },
      }
      await updateEnquetePub({
        variables: variables,
      })
    },
    [updateEnquetePub, id]
  )

  return { handleUpdateEnquete, handleUpdateEnquetePub }
}
