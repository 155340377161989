import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import { QuizFormValues } from 'services/validators/QuizFormSchema'
import { createOgp } from 'utils/createOgp'
import { useUploadFile } from 'hooks/useUploadFile'
import { useUserProfileContext } from 'state'
import {
  CreateQuizData,
  CreateQuizVars,
  CREATE_QUIZ,
} from 'graphql/apollo/mutation/quiz/createQuiz'
import { QUIZZES } from 'graphql/apollo/query/quizzes'

export const useCreateQuiz = () => {
  const { currentGroup } = useUserProfileContext()
  const { handleUploadDataUrlImage } = useUploadFile()

  const [createQuiz] = useMutation<CreateQuizData, CreateQuizVars>(CREATE_QUIZ)

  const handleCreateQuiz = useCallback(
    async (values: QuizFormValues) => {
      const s3ImageUrl = await handleUploadDataUrlImage(values.imageUrl, 'quiz')
      const s3AnswerImageUrl = await handleUploadDataUrlImage(
        values.answerImageUrl,
        'quiz-answer'
      )
      const s3CorrectImageUrl = await handleUploadDataUrlImage(
        values.correctImageUrl,
        'quiz-correct'
      )
      const s3BgImageUrl = await handleUploadDataUrlImage(
        values.bgImageUrl,
        'quiz-bg'
      )
      const ogpImageUrl = await createOgp(values.question, values.imageUrl)
      const s3OgpImageUrl = await handleUploadDataUrlImage(
        ogpImageUrl,
        'quiz-ogp'
      )

      const uploadWrongImageUrls = async () => {
        const s3ImageUrls = []

        try {
          for (const imageUrl of values.wrongImageUrls) {
            if (imageUrl === '') {
              s3ImageUrls.push(imageUrl)
              continue
            }

            try {
              const s3ImageUrl = await handleUploadDataUrlImage(
                imageUrl,
                'quiz-wrong'
              )
              s3ImageUrls.push(s3ImageUrl)
            } catch (error) {
              console.error(`Error uploading image for ${imageUrl}:`, error)
              s3ImageUrls.push('')
            }
          }

          return s3ImageUrls
        } catch (error) {
          console.error('Error uploading images:', error)
          return []
        }
      }

      const s3WrongImageUrls = await uploadWrongImageUrls()

      await createQuiz({
        variables: {
          input: {
            answerImageUrl: s3AnswerImageUrl,
            correct: values.correct,
            correctImageUrl: s3CorrectImageUrl,
            design: values.design as 'SERIOUS' | 'SIMPLE' | 'ORIGINAL',
            description: values.description,
            imageUrl: s3ImageUrl,
            published: values.published,
            question: values.question,
            snsInfo: {
              destinationLink: values.destinationLink,
              destinationLinkText: values.destinationLinkText,
              ogpImageUrl: s3OgpImageUrl,
              shareText: values.snsShareText,
            },
            wrongs: values.wrongs,
            wrongImageUrls: s3WrongImageUrls,
            ownerGroupId: currentGroup?.id,
            bgImageUrl: s3BgImageUrl,
          },
        },
        refetchQueries: [{ query: QUIZZES }, 'Quizzes'],
      })
    },
    [currentGroup?.id, createQuiz, handleUploadDataUrlImage]
  )

  return { handleCreateQuiz }
}
