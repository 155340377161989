import React from 'react'
import { Grid, GridItem, Text } from '@chakra-ui/react'
import { FormUploadImage } from 'v2components/Common/InputPageComponent/FormUploadImage'

export const QuizImageForm: React.FC = () => {
  return (
    <Grid
      templateRows={{
        base: 'repeat(3, 1fr)',
        sm: 'repeat(1, 1fr)',
      }}
      templateColumns={{
        base: 'repeat(1, 1fr)',
        sm: 'repeat(3, 1fr)',
      }}
      gap={4}
    >
      <GridItem rowSpan={{ base: 1, sm: 1 }} colSpan={{ base: 1, sm: 1 }}>
        <Text fontWeight="500" mb="8px">
          問題画像
        </Text>
        <FormUploadImage name="imageUrl" />
      </GridItem>
      <GridItem rowSpan={{ base: 1, sm: 1 }} colSpan={{ base: 1, sm: 1 }}>
        <Text fontWeight="500" mb="8px">
          解答画像
        </Text>
        <FormUploadImage name="answerImageUrl" />
      </GridItem>
      <GridItem rowSpan={{ base: 1, sm: 1 }} colSpan={{ base: 1, sm: 1 }}>
        <Text fontWeight="500" mb="8px">
          背景画像
        </Text>
        <FormUploadImage name="bgImageUrl" />
      </GridItem>
    </Grid>
  )
}
