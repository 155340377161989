import React, { useState, useEffect } from 'react'
import { FormControl, IconButton, Box } from '@chakra-ui/react'
import { getIn, useFormikContext } from 'formik'
import { fileToDataUrl } from 'utils/fileToDataUrl'
import { ImageUpload, ImageType } from 'v2components/ImageUpload'
import { MdClose } from 'react-icons/md'

interface Props {
  name: string
  type?: ImageType
}

export const FormUploadImage: React.FC<Props> = ({ name, type = 'NORMAL' }) => {
  const { values, setFieldValue } = useFormikContext()
  const [imageValue, setImageValue] = useState('')

  useEffect(() => {
    const value = getIn(values, name)
    setImageValue(value)
  }, [values, name])

  return (
    <FormControl>
      <Box position="relative">
        {imageValue && (
          <IconButton
            isRound={true}
            variant="solid"
            bg="buttonPrimary"
            aria-label="Done"
            border="2px solid #fff"
            size="xs"
            icon={<MdClose size="16px" color="#fff" />}
            position="absolute"
            top={type !== 'MIN' ? '4px' : '-10px'}
            left={type !== 'MIN' ? '4px' : '-10px'}
            zIndex="1"
            cursor="pointer"
            onClick={() => {
              setFieldValue(name, '')
              setImageValue('')
            }}
            _hover={{
              opacity: 0.8,
            }}
          />
        )}
        <ImageUpload
          imageUrl={imageValue}
          onDrop={async (acceptedFiles) => {
            if (acceptedFiles && acceptedFiles.length > 0) {
              const imageDataUrl = await fileToDataUrl(acceptedFiles[0])
              setFieldValue(name, imageDataUrl)
            }
          }}
          type={type}
        />
      </Box>
    </FormControl>
  )
}
