import React from 'react'
import {
  VStack,
  HStack,
  Text,
  Button,
  Heading,
  Stack,
  Grid,
  GridItem,
  Box,
  Center,
  Image,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
// icons
import { BsCircle } from 'react-icons/bs'
import { ImCross } from 'react-icons/im'

import {
  TwitterShareButton,
  XIcon,
  FacebookShareButton,
  FacebookIcon,
} from 'react-share'
import truncate from 'lodash/truncate'

import { Quiz, Stats } from 'types/generated/graphql'

export interface QuizProps {
  quiz: Quiz
  quizOptions: string[]
  quizStats?: Stats
  selectedChoice: string | undefined
}

const ImageWrapper = css`
  padding-top: 100%;
  position: relative;
  height: 100%;
`
const ImageBox = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #f3b0ae;
`
const ImageWrapperChoice = css`
  padding-top: 40%;
  position: relative;
  height: 100%;
`
const ImageBoxChoice = css`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`

// http://localhost:3000/quiz/hoge
export const MultipleChoicePlayAnswer: React.FC<QuizProps> = ({
  quiz,
  quizOptions,
  quizStats,
  selectedChoice,
}) => {
  const correct = quiz.content.correct
  const design = quiz.design

  const { origin } = new URL(window.location.href)
  const shareUrl = `${origin}/quiz/${quiz.id}/play`
  const sendOnLink = quiz.snsInfo.destinationLink ?? ''
  const sendOnLinkText = quiz.snsInfo.destinationLinkText
    ? truncate(quiz.snsInfo.destinationLinkText, {
        length: 25,
      })
    : 'リンク'

  const deafultSnsShareText = quiz.snsInfo.shareText
    ? quiz.snsInfo.shareText
    : `「${quiz.content.question}」を解きました！`

  const explanatoryNote = () => {
    if (quiz.content.description) {
      return (
        <Center py={5}>
          <VStack>
            <Text
              fontSize={{ base: 'md', sm: 'lg' }}
              mb="0.5rem"
              fontWeight="bold"
              color={`${design}.common.mainText`}
              whiteSpace="nowrap"
            >
              解説
            </Text>
            <Text color={`${design}.common.mainText`}>
              {quiz.content.description}
            </Text>
          </VStack>
        </Center>
      )
    }
  }

  return (
    <VStack w={{ base: '90%', md: '70%' }} p={4}>
      {selectedChoice === correct ? (
        <Heading
          as="h3"
          size="lg"
          mb="1rem"
          color={`${design}.answered.result.correctLabel`}
        >
          正解
        </Heading>
      ) : (
        <Heading
          as="h3"
          size="lg"
          mb="0.5rem"
          color={`${design}.answered.result.incorrectLabel`}
        >
          不正解
        </Heading>
      )}
      {design === 'SIMPLE' || design === 'SERIOUS' ? (
        <Box mb="0.5rem">
          <Box css={ImageWrapperChoice}>
            <Image
              css={ImageBoxChoice}
              src={
                quiz.content.answerImageUrl ||
                quiz.content.imageUrl ||
                '/images/whiteImage.png'
              }
              alt="リスト画像"
            />
          </Box>
        </Box>
      ) : (
        <Box w={{ base: '100%', md: '60%' }} mb="0.5rem">
          <Box css={ImageWrapper}>
            <Image
              css={ImageBox}
              src={
                quiz.content.answerImageUrl ||
                quiz.content.imageUrl ||
                '/images/whiteImage.png'
              }
              alt="リスト画像"
            />
          </Box>
        </Box>
      )}
      <Text
        fontSize={{ base: 'md', sm: 'lg' }}
        mb="0.5rem"
        py="1rem"
        color={`${design}.common.mainText`}
      >
        Q：{quiz.content.question}
        {quizStats &&
          // statsには[正解、不正解1、不正解2…]の順番で入る
          `(正答率${quizStats.stats[0].percent})`}
      </Text>
      <Stack w="100%" mb="2rem" spacing={4} direction="row" align="center">
        <Grid
          w="100%"
          templateRows={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(4, 1fr)' }}
          gap={4}
        >
          {quizOptions.map((quiz, index) => {
            if (!quiz) {
              return null
            }
            if (quiz === correct) {
              return (
                <GridItem
                  w="100%"
                  rowSpan={{ base: 1, md: 1 }}
                  colSpan={{ base: 1, md: 2 }}
                  key={'quizAnswer-' + index}
                >
                  <Box
                    boxShadow="base"
                    p="4"
                    rounded="md"
                    bg={`${design}.answered.choiceAnswered.background`}
                    border={design === 'SIMPLE' ? '1px' : ''}
                    borderColor={`${design}.answered.result.correctBorder`}
                    w="100%"
                  >
                    <Center color={`${design}.answered.result.correctLabel`}>
                      <BsCircle />：<Text fontWeight="bold">{quiz}</Text>
                    </Center>
                  </Box>
                </GridItem>
              )
            } else if (selectedChoice === quiz) {
              return (
                <GridItem
                  w="100%"
                  rowSpan={{ base: 1, md: 1 }}
                  colSpan={{ base: 1, md: 2 }}
                  key={'quizAnswer-' + index}
                >
                  <Box
                    boxShadow="base"
                    p="4"
                    rounded="md"
                    border={design === 'SIMPLE' ? '1px' : ''}
                    borderColor={`${design}.answered.result.incorrectBorder`}
                    bg={`${design}.answered.choiceAnswered.background`}
                    w="100%"
                  >
                    <Center color={`${design}.answered.result.incorrectLabel`}>
                      <ImCross />：{quiz}
                    </Center>
                  </Box>
                </GridItem>
              )
            } else {
              return (
                <GridItem
                  w="100%"
                  rowSpan={{ base: 1, md: 1 }}
                  colSpan={{ base: 1, md: 2 }}
                  key={'quizAnswer-' + index}
                >
                  <Box
                    boxShadow="base"
                    p="4"
                    rounded="md"
                    border={design === 'SIMPLE' ? '1px' : ''}
                    w="100%"
                    bg={`${design}.answered.choiceAnswered.background`}
                  >
                    <Center color={`${design}.answered.choiceAnswered.label`}>
                      {quiz}
                    </Center>
                  </Box>
                </GridItem>
              )
            }
          })}
        </Grid>
      </Stack>
      {explanatoryNote()}
      <HStack>
        <TwitterShareButton
          url={shareUrl}
          title={deafultSnsShareText}
          hashtags={['QuzillA']}
        >
          <XIcon size={32} borderRadius={10} />
        </TwitterShareButton>
        <FacebookShareButton url={shareUrl} hashtag={'QuzillA'}>
          <FacebookIcon size={32} borderRadius={10} />
        </FacebookShareButton>
      </HStack>
      {sendOnLink === '' ? (
        <Box />
      ) : (
        <Button
          bg={`${design}.answered.button.background`}
          mb={3}
          bottom="0"
          as="a"
          href={sendOnLink}
          target="_blank"
        >
          {sendOnLinkText}
        </Button>
      )}
    </VStack>
  )
}
