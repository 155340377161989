import React, { useMemo, useRef, useEffect } from 'react'
import truncate from 'lodash/truncate'
import {
  VStack,
  HStack,
  Text,
  Heading,
  Button,
  Box,
  Center,
  List,
  ListItem,
  Flex,
  Link,
} from '@chakra-ui/react'
import {
  TwitterShareButton,
  XIcon,
  FacebookShareButton,
  FacebookIcon,
} from 'react-share'
import { BsCircle } from 'react-icons/bs'
import { ImCross } from 'react-icons/im'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { Playlist } from 'types/generated/graphql'

type Props = {
  playlist: Playlist
  quizzesCount: number
  score: number
  answers: string[]
  quizListId?: string
}

export const EmbedResult: React.FC<Props> = ({
  playlist,
  quizzesCount,
  score,
  answers,
  quizListId = '',
}) => {
  const elm = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (!elm.current) {
      return
    }
    const { clientHeight } = elm.current
    window.parent.postMessage([clientHeight, quizListId], '*')
  }, [])

  const resultTheme = useMemo(() => {
    let passingScore = playlist.passingScore || 0
    if (score >= passingScore) {
      return '#fa8826'
    } else {
      return '#37517d'
    }
  }, [playlist.passingScore, score])

  const { origin } = new URL(window.location.href)
  const shareUrl = `${origin}/quizplaylist/${playlist.id}/play`
  const sendOnLink = playlist.snsInfo.destinationLink || ''
  const sendOnLinkText =
    truncate(playlist.snsInfo.destinationLinkText || '', { length: 25 }) ||
    'リンク'

  const snsShareText = (() => {
    const resultMsg = `${quizzesCount}問中${score}問正解でした！`
    if (playlist.snsInfo.shareText) {
      return [playlist.snsInfo.shareText, resultMsg].join('\n')
    }
    return [playlist.name, resultMsg].join('\n')
  })()

  // 合格点設定によるテキスト表示の分岐
  const resultTextComponent = () => {
    // 合格点がクイズ数を上回った場合は満点を合格点とする
    let passingScore = playlist.passingScore || 0
    if (passingScore > quizzesCount) {
      passingScore = quizzesCount
    }

    // 得点が合格点を上回っているか否かを判定し、それぞれの場合に設定された表示文があればその文字列を表示、なければ何も表示しない
    const displayText =
      (score >= passingScore && playlist.passText) ||
      (score < passingScore && playlist.failText)
    const buttonText =
      score >= passingScore ? playlist.passLinkText : playlist.failLinkText
    const buttonLink =
      score >= passingScore ? playlist.passLink : playlist.failLink

    const linkButton = buttonText && buttonLink && (
      <Link href={buttonLink} target="_blank">
        <Button
          background={score >= passingScore ? '#EB8E41' : '#37517D'}
          borderRadius="48px"
          h="48px"
          color="#fff"
          paddingLeft="24px"
          paddingRight="16px"
          fontSize="16px"
          fontWeight="500"
          _hover={{
            background: score >= passingScore ? '#eb7917' : '#172e57',
          }}
        >
          {buttonText}
          <MdKeyboardArrowRight size="24" />
        </Button>
      </Link>
    )

    if (displayText) {
      return (
        <>
          <Text fontSize="md">{displayText}</Text>
          {linkButton && (
            <Flex justifyContent="center" mt="20px">
              {linkButton}
            </Flex>
          )}
        </>
      )
    }
  }

  let isPlayPage = false
  const url = window.location.href // 現在のページURLを取得
  if (url.indexOf('/embed/play') > -1) {
    isPlayPage = true
  }

  return (
    <VStack
      w="100%"
      minH={isPlayPage ? '100vh' : '0'}
      position="relative"
      overflow="hidden"
      borderRadius="16px"
      boxSizing="border-box"
      bg={score >= (playlist.passingScore || 0) ? '#fff3b2' : '#dfe3ea'}
      ref={elm}
    >
      <Box p="15px 30px" color="#333" w="100%">
        <Heading
          w="100%"
          borderRadius="6px"
          p=".2em"
          textAlign="center"
          color="#fff"
          m=".4em 0"
          bg={resultTheme}
          position="relative"
          fontSize="24px"
          _after={{
            borderLeft: '5px solid transparent',
            borderRight: '5px solid transparent',
            borderTop: '10px solid transparent',
            borderBottom: '10px solid transparent',
            borderTopColor: `${resultTheme}`,
            borderBottomWidth: '0',
            bottom: '-10px',
            content: '""',
            display: 'block',
            left: 0,
            right: 0,
            m: 'auto',
            position: 'absolute',
            w: 0,
          }}
        >
          クイズ結果
        </Heading>
        <Heading
          pt=".2em"
          m="0 0 .4em"
          textAlign="center"
          fontSize="1.17em"
          fontWeight="bold"
        >
          {score}/{quizzesCount}正解！
        </Heading>

        <Box mb="24px" w="100%">
          {resultTextComponent()}
        </Box>

        <List
          spacing={3}
          m="0 0 20px"
          p="10px"
          bg="#fff"
          borderRadius="6px"
          textAlign="center"
        >
          {playlist.quizzes.map((quiz, index) => {
            return (
              <ListItem
                key={index}
                borderBottom={
                  index === playlist.quizzes.length - 1 ? 'none' : '1px dashed'
                }
                borderColor={resultTheme}
                pb="8px"
                display="flex"
                w="100%"
                position="relative"
                alignItems="center"
                mb={index === playlist.quizzes.length - 1 ? 'none' : '8px'}
              >
                <Text
                  textAlign="center"
                  textShadow="none"
                  w="3em"
                  minW="3em"
                  bg={resultTheme}
                  color="#fff"
                  borderRadius="6px"
                  p=".2em"
                  boxSizing="border-box"
                  m="0 .5em 0 0"
                  fontSize="18px"
                  fontWeight="700"
                  lineHeight="1.6em;"
                >
                  {`Q${index + 1}`}
                </Text>
                <Text
                  fontSize="28px"
                  color={resultTheme}
                  alignItems="center"
                  position="absolute"
                  left="50%"
                  transform={'translateX(-50%)'}
                >
                  {answers[index] === quiz.content.correct ? (
                    <BsCircle />
                  ) : (
                    <ImCross />
                  )}
                </Text>
              </ListItem>
            )
          })}
        </List>

        <Center>
          <HStack>
            <TwitterShareButton
              url={shareUrl}
              title={snsShareText}
              hashtags={['QuzillA']}
            >
              <XIcon size={40} borderRadius={100} />
            </TwitterShareButton>
            <FacebookShareButton url={shareUrl} hashtag={'QuzillA'}>
              <FacebookIcon size={40} borderRadius={100} />
            </FacebookShareButton>
          </HStack>
        </Center>

        {sendOnLink && (
          <Center mt="1em">
            <Button
              bg={resultTheme}
              _hover={{
                bg:
                  score >= (playlist.passingScore || 0) ? '#eb7917' : '#172e57',
              }}
              position="relative"
              textAlign="center"
              color="#fff"
              fontSize="18px"
              lineHeight="1em"
              fontWeight="700"
              m="0 auto 15px"
              p="10px"
              display="block"
              textDecoration="none"
              borderRadius="6px"
              minW="16em"
              maxW="100%"
              boxSizing="border-box"
              as="a"
              href={sendOnLink}
              target="_blank"
              _before={{
                content: '""',
                display: 'inline-block',
                w: '10px',
                h: '10px',
                m: '0 10px 2px 0',
                borderTop: '2px solid #fff',
                borderRight: '2px solid #fff',
                transform: 'rotate(45deg)',
              }}
            >
              {sendOnLinkText}
            </Button>
          </Center>
        )}
      </Box>
    </VStack>
  )
}
