import { gql } from '@apollo/client'
import { Enquete, Scalars } from 'types/generated/graphql'

export type EnqueteData = {
  enquete: Enquete
}

export type EnqueteVars = {
  id: Scalars['ID']
}

export const ENQUETE = gql`
  query Enquete($id: ID!) {
    enquete(id: $id) {
      id
      title
      question
      optionsCsv
      optionsImageUrls
      imageUrl
      createdAt
      updatedAt
      published
      selectMax
      selectMin
      periodDisplayFlag
      numberOfVoteDisplayFlag
      showResult
      resultText
      allowDuplicateAnswer
      startDate
      deadline
      displayUnit
      ownerUser {
        id
        name
      }
      ownerGroup {
        id
        name
      }
    }
  }
`
