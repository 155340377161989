import { useState, useCallback, useMemo } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import shuffle from 'lodash/shuffle'

import {
  PLAYLIST,
  PlaylistData,
  PlaylistVars,
} from 'graphql/apollo/query/playlist'
import {
  ANSWER_QUIZ,
  AnswerQuizData,
  AnswerQuizVars,
} from 'graphql/apollo/mutation/answer/answerQuiz'
import { isAnsweredQuiz, saveAnsweredQuiz } from 'utils/answeredQuiz'

export const ProgressStatus = {
  // スタート
  START: 'START',
  // 出題/回答
  QUESTION: 'QUESTION',
  // 回答結果
  ANSWERD: 'ANSWERD',
  // 総合成績
  RESULT: 'RESULT',
} as const

export const usePlayQuizPlaylist = (
  quizPlaylistId: string,
  isPreview: boolean = false
) => {
  const [progressStatus, setProgressStatus] = useState<
    keyof typeof ProgressStatus
  >(ProgressStatus.START)

  const [quizNo, setQuizNo] = useState<number>(1)
  const [answers, setAnswers] = useState<string[]>([])
  const [score, setScore] = useState<number>(0)

  const { loading, error, data, refetch } = useQuery<
    PlaylistData,
    PlaylistVars
  >(PLAYLIST, {
    variables: {
      id: quizPlaylistId,
      onlyPublishedQuizzes: true,
    },
    skip: !quizPlaylistId,
    context: {
      noAuthorizationHeader: !isPreview,
    },
  })
  const [answerQuiz] = useMutation<AnswerQuizData, AnswerQuizVars>(ANSWER_QUIZ)

  const currentQuiz = useMemo(() => {
    const quiz = data?.playlist?.quizzes[quizNo - 1]
    if (!quiz) {
      return undefined
    }

    // 選択肢を順番ランダムで生成
    const answerCandidates = shuffle([
      quiz.content.correct,
      ...quiz.content.wrongs,
    ])

    // ランダムにした選択肢をを画像の順番にも反映
    const wrongImageUrls = quiz.content.wrongImageUrls ?? []
    const imageUrls = answerCandidates.map((candidate) =>
      candidate === quiz.content.correct
        ? quiz.content.correctImageUrl ?? ''
        : wrongImageUrls[quiz.content.wrongs.indexOf(candidate)] ?? ''
    )

    return {
      ...quiz,
      imageUrls,
      answerCandidates,
    }
  }, [data, quizNo])

  const quizzesCount = useMemo(() => {
    return data?.playlist?.quizzes.length || 0
  }, [data])

  const handleStart = useCallback(() => {
    setProgressStatus(ProgressStatus.QUESTION)
  }, [])

  const handleAnswer = useCallback(
    (answer: string) => {
      setAnswers((answers) => [...answers, answer])

      // 正解なら1点加算
      if (currentQuiz?.content.correct === answer) {
        setScore((score) => score + 1)
      }
      setProgressStatus(ProgressStatus.ANSWERD)
      if (!isPreview && currentQuiz?.id && !isAnsweredQuiz(currentQuiz.id)) {
        answerQuiz({
          variables: {
            input: {
              answer: answer,
              quizId: currentQuiz.id,
            },
          },
        })
        saveAnsweredQuiz(currentQuiz.id)
      }
    },
    [currentQuiz, isPreview, answerQuiz]
  )

  const handleNext = useCallback(() => {
    const nextQuizNo = quizNo + 1
    if (nextQuizNo > quizzesCount) {
      setProgressStatus(ProgressStatus.RESULT)
      return
    }

    setProgressStatus(ProgressStatus.QUESTION)
    setQuizNo(nextQuizNo)
  }, [quizNo, quizzesCount])

  return {
    loading,
    error,
    refetch,
    progressStatus,
    playlist: data?.playlist,
    quizNo,
    answers,
    score,
    currentQuiz,
    quizzesCount,
    handleStart,
    handleAnswer,
    handleNext,
  }
}
