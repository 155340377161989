import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  createContext,
} from 'react'
import { Formik, Form, Field, FieldProps } from 'formik'
import dayjs from 'dayjs'
import {
  Container,
  Box,
  Stack,
  Center,
  Flex,
  FormControl,
  Checkbox,
  Button,
  IconButton,
  Text,
  HStack,
  useDisclosure,
} from '@chakra-ui/react'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import { Enquete, EnqueteStats, DisplayUnit } from 'types/generated/graphql'
import {
  EnqueteFormSchema,
  EnqueteFormValues,
} from 'services/validators/EnqueteFormSchema'
import { FormInput } from 'v2components/Common/InputPageComponent/FormInput'
import { FormInputOptions } from 'v2components/Common/InputPageComponent/FormInputOptions'
import { FormTextarea } from 'v2components/Common/InputPageComponent/FormTextarea'
import { EnqueteImageForm } from './EnqueteImageForm'
import { FormDateTime } from '../Common/InputPageComponent/FormDateTime'
import { FormRadio } from '../Common/InputPageComponent/FormRadio'
import { navigate } from '@reach/router'
import { ContentsHeader } from 'v2components/Common/ContentsHeader'
import { EditPreviewLayout } from 'v2components/Common/EditPreviewLayout'
import { LivePreviewEnquete } from 'v2components/Common/LivePreview/LivePreviewEnquete'
import { LivePreviewModal } from 'v2components/Modal/LivePreviewModal'
import { FormUploadImage } from 'v2components/Common/InputPageComponent/FormUploadImage'

type Props = {
  initialValues?: Enquete
  enqueteStats?: EnqueteStats
  submitButtonLabel: string
  headerTitle: string
  onSubmit: (values: EnqueteFormValues) => void
}

type FormDateProps = {
  setDeadlineFlg: React.Dispatch<React.SetStateAction<boolean>>
  deadlineFlg: boolean
  answerPeriodFlg: boolean
}
export const FormDateContext = createContext<FormDateProps>({
  setDeadlineFlg: () => {},
  deadlineFlg: false,
  answerPeriodFlg: false,
})

type FormOptionProps = {
  setInvalidFlg: React.Dispatch<React.SetStateAction<boolean>>
}
export const FormOptionContext = createContext<FormOptionProps>({
  setInvalidFlg: () => {},
})

export const EnqueteForm: React.FC<Props> = ({
  initialValues,
  enqueteStats,
  submitButtonLabel,
  headerTitle,
  onSubmit,
}) => {
  const pmDisclosure = useDisclosure()
  const getStatLabel = (index: number) => {
    if (!enqueteStats?.stats[index]) {
      return ''
    }
    return `　${enqueteStats?.stats[index].count}票(${enqueteStats?.stats[index].percent})`
  }
  const [optionsCsv, setOptionsCsv] = useState(() => {
    if (!initialValues) {
      return [
        { name: 'optionsCsv.0', label: '選択肢1', placeholder: '選択肢1' },
        { name: 'optionsCsv.1', label: '選択肢2', placeholder: '選択肢2' },
      ]
    }

    return initialValues.optionsCsv.split(',').map((_, i) => ({
      name: `optionsCsv.${i}`,
      label: `選択肢${i + 1}${getStatLabel(i)}`,
      placeholder: `選択肢${i + 1}`,
    }))
  })

  const [optionsImageUrls, setOptionsImageUrls] = useState(() => {
    if (!initialValues || !initialValues.optionsImageUrls) {
      return ['optionsImage.0', 'optionsImage.1']
    }
    const arr = initialValues.optionsImageUrls.split(',')
    const nonEmptyArr = arr.filter(Boolean)
    if (nonEmptyArr.length > 0) {
      return initialValues.optionsImageUrls.split(',').map((image) => image)
    }
    return initialValues.optionsCsv.split(',').map(() => ``)
  })

  const delOptionsCsv = useCallback(
    (values: EnqueteFormValues) => {
      optionsCsv.pop()
      setOptionsCsv([...optionsCsv])
      values.optionsCsv.pop()
      optionsImageUrls.pop()
      setOptionsImageUrls([...optionsImageUrls])
      values.optionsImageUrls.pop()
    },
    [optionsCsv, setOptionsCsv, optionsImageUrls, setOptionsImageUrls]
  )

  const addOptionsCsv = useCallback(
    (values: EnqueteFormValues) => {
      setOptionsCsv([
        ...optionsCsv,
        {
          name: `optionsCsv.${optionsCsv.length}`,
          label: `選択肢${optionsCsv.length + 1}`,
          placeholder: `選択肢${optionsCsv.length + 1}`,
        },
      ])
      values.optionsCsv.push('')
      setOptionsImageUrls([...optionsImageUrls, ``])
      values.optionsImageUrls.push(``)
    },
    [optionsCsv, setOptionsCsv, optionsImageUrls, setOptionsImageUrls]
  )

  const content = useMemo<EnqueteFormValues>(() => {
    if (!initialValues) {
      return EnqueteFormSchema.getDefault()
    }

    return {
      title: initialValues.title,
      question: initialValues.question,
      periodDisplayFlag: initialValues.periodDisplayFlag,
      numberOfVoteDisplayFlag: initialValues.numberOfVoteDisplayFlag,
      showResult: initialValues.showResult ?? true,
      resultText: initialValues.resultText ?? '',
      allowDuplicateAnswer: initialValues.allowDuplicateAnswer ?? false,
      deadline: dayjs(initialValues.deadline).format('YYYY-MM-DD HH:mm'),
      startDate: dayjs(initialValues.startDate).format('YYYY-MM-DD HH:mm'),
      optionsCsv: initialValues.optionsCsv.split(','),
      optionsImageUrls: initialValues.optionsImageUrls
        ? initialValues.optionsImageUrls.split(',')
        : Array(initialValues.optionsCsv.split(',').length).fill(''),
      published: initialValues.published,
      imageUrl: initialValues.imageUrl ?? '',
      displayUnit: initialValues.displayUnit,
    }
  }, [initialValues])

  const [answerPeriodFlg, setAnswerPeriodFlg] = useState<boolean>(
    content.periodDisplayFlag
  )
  const [deadlineFlg, setDeadlineFlg] = useState<boolean>(false)
  const [invalidFlg, setInvalidFlg] = useState<boolean>(true)
  const enqueteUrl = window.location.href

  useEffect(() => {
    if (enqueteUrl.includes('edit')) {
      setInvalidFlg(false)
    }
  }, [enqueteUrl])

  return (
    <Formik
      initialValues={EnqueteFormSchema.cast(content, {
        stripUnknown: true,
      })}
      validationSchema={EnqueteFormSchema}
      onSubmit={onSubmit}
      validateOnBlur
    >
      {({ values, errors, touched, isSubmitting, isValid, validateForm }) => {
        return (
          <>
            <Flex
              bgColor={'#fff'}
              flex={'1 1 auto'}
              flexDirection={'column'}
              p={'24px 20px'}
              borderRadius={'24px'}
            >
              <ContentsHeader
                contentTypeTitle={headerTitle}
                contentType="enquete"
                isListPage={false}
              />
              <Box overflowY="scroll">
                <Form>
                  <Container maxW="100%">
                    <Stack spacing="24px">
                      <EnqueteImageForm />

                      <FormInput
                        name="title"
                        label="タイトル"
                        placeholder="タイトル: 40文字制限です"
                        annotation="「タイトル」はQuzillA管理画面上でのみ表示されます"
                        isRequired
                      />
                      <FormTextarea
                        name="question"
                        label="質問文"
                        placeholder="質問文: 120文字制限です"
                        isRequired
                      />

                      <Box>
                        <FormRadio
                          name="displayUnit"
                          label="結果画面で表示する単位"
                          values={[
                            DisplayUnit.Percentage,
                            DisplayUnit.Vote,
                            DisplayUnit.Both,
                          ]}
                          radioBtnLabels={['％', '票数', '％・票数']}
                          isRequired
                        />

                        <Field name="numberOfVoteDisplayFlag">
                          {({ form }: FieldProps) => (
                            <FormControl
                              //   name="numberOfVoteDisplayFlag"
                              display="flex"
                              alignItems="center"
                            >
                              <Checkbox
                                name="numberOfVoteDisplayFlag"
                                colorScheme="blue"
                                defaultChecked={values.numberOfVoteDisplayFlag}
                                onChange={() => {
                                  form.setFieldValue(
                                    'numberOfVoteDisplayFlag',
                                    !values.numberOfVoteDisplayFlag
                                  )
                                }}
                              >
                                <Text fontSize="14px">
                                  結果画面に総投票数を表示する
                                </Text>
                              </Checkbox>
                            </FormControl>
                          )}
                        </Field>
                      </Box>
                      <Box>
                        {enqueteStats && (
                          <Text fontSize="sm" mb="1rem">
                            総投票数:{enqueteStats.total}件
                          </Text>
                        )}
                        <FormOptionContext.Provider
                          value={{
                            setInvalidFlg,
                          }}
                        >
                          <Stack spacing="16px">
                            {optionsCsv.map((option, i) => (
                              <HStack spacing="12px" key={i}>
                                <Box w="60px">
                                  <FormUploadImage
                                    key={`optionsImageUrls.${i}`}
                                    name={`optionsImageUrls.${i}`}
                                    type="MIN"
                                  />
                                </Box>
                                <Box flex="1">
                                  <FormInputOptions
                                    key={option.name}
                                    name={option.name}
                                    label={option.label}
                                    placeholder={`${option.placeholder} : 20文字制限です`}
                                  />
                                </Box>
                              </HStack>
                            ))}
                          </Stack>
                        </FormOptionContext.Provider>
                        {touched.optionsCsv &&
                          errors.optionsCsv &&
                          errors.optionsCsv !== '選択肢は2つ以上必要です' && (
                            <Text fontSize="12px" color="v2Attention" mt="8px">
                              {errors.optionsCsv}
                            </Text>
                          )}
                        <Text fontSize="1px" color="blackAlpha.500">
                          ※ 選択肢の上限数は20個です
                        </Text>
                        <Center mt="16px">
                          {optionsCsv.length < 20 && (
                            <IconButton
                              bgColor="v2Primary"
                              color="#fff"
                              _hover={{
                                bgColor: 'v2Primary',
                                opacity: '0.85',
                              }}
                              aria-label="選択肢欄追加"
                              isRound
                              icon={<AddIcon />}
                              onClick={() => addOptionsCsv(values)}
                              mr={2}
                            />
                          )}
                          {optionsCsv.length > 2 && (
                            <IconButton
                              bgColor="v2Attention"
                              color="#fff"
                              _hover={{
                                bgColor: 'v2Attention',
                                opacity: '0.85',
                              }}
                              aria-label="選択肢欄削除"
                              isRound
                              icon={<MinusIcon />}
                              onClick={() => {
                                delOptionsCsv(values)
                                validateForm()
                              }}
                            />
                          )}
                        </Center>
                      </Box>

                      <Box>
                        <Field name="periodDisplayFlag">
                          {({ form }: FieldProps) => (
                            <FormControl
                              // name="periodDisplayFlag"
                              display="flex"
                              alignItems="center"
                              mb={4}
                            >
                              <Box>
                                <Checkbox
                                  name="periodDisplayFlag"
                                  colorScheme="blue"
                                  defaultChecked={values.periodDisplayFlag}
                                  onChange={() => {
                                    form.setFieldValue(
                                      'periodDisplayFlag',
                                      !values.periodDisplayFlag
                                    )
                                    setAnswerPeriodFlg(
                                      !values.periodDisplayFlag
                                    )
                                  }}
                                >
                                  <Text fontSize="14px" fontWeight="500">
                                    回答期限を表示する
                                  </Text>
                                </Checkbox>
                              </Box>
                            </FormControl>
                          )}
                        </Field>
                        <FormDateContext.Provider
                          value={{
                            deadlineFlg,
                            answerPeriodFlg,
                            setDeadlineFlg,
                          }}
                        >
                          <Box>
                            <Flex direction={{ base: 'column', md: 'row' }}>
                              <FormDateTime
                                name="startDate"
                                label="回答開始日"
                              />
                              <FormDateTime
                                name="deadline"
                                label="回答終了日"
                                isRequired={answerPeriodFlg}
                              />
                            </Flex>
                            {(() => {
                              if (
                                (errors && errors.startDate) ||
                                (errors && errors.deadline) ||
                                (errors && errors.periodDisplayFlag)
                              ) {
                                return (
                                  <Text
                                    fontSize="12px"
                                    color="v2Attention"
                                    mt="8px"
                                  >
                                    {errors.startDate}
                                    {errors.deadline}
                                    {errors.periodDisplayFlag}
                                  </Text>
                                )
                              }
                            })()}
                          </Box>
                        </FormDateContext.Provider>
                      </Box>
                      <Field name="showResult">
                        {({ form }: FieldProps) => (
                          <FormControl
                            display="flex"
                            alignItems="center"
                            mb={4}
                          >
                            <Box>
                              <Checkbox
                                name="showResult"
                                colorScheme="blue"
                                defaultChecked={values.showResult}
                                onChange={() => {
                                  form.setFieldValue(
                                    'showResult',
                                    !values.showResult
                                  )
                                }}
                              >
                                <Text fontSize="14px" fontWeight="500">
                                  結果を表示する
                                </Text>
                              </Checkbox>
                            </Box>
                          </FormControl>
                        )}
                      </Field>
                      <FormInput
                        name="resultText"
                        label="結果非表示時テキスト"
                        placeholder="結果非表示時に表示されるテキストです"
                      />
                      <Field name="allowDuplicateAnswer">
                        {({ form }: FieldProps) => (
                          <FormControl display="flex" alignItems="center">
                            <Checkbox
                              name="allowDuplicateAnswer"
                              colorScheme="blue"
                              defaultChecked={values.allowDuplicateAnswer}
                              onChange={() => {
                                form.setFieldValue(
                                  'allowDuplicateAnswer',
                                  !values.allowDuplicateAnswer
                                )
                              }}
                            >
                              <Text fontSize="14px" fontWeight="500">
                                多重投票を許可する
                              </Text>
                            </Checkbox>
                          </FormControl>
                        )}
                      </Field>
                      <Stack
                        direction="column"
                        spacing="24px"
                        bgColor="#EAF7FF"
                        p="24px"
                        borderRadius="10px"
                        w="100%"
                      >
                        <Field name="published">
                          {({ form }: FieldProps) => (
                            <FormControl
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Checkbox
                                name="published"
                                id="published"
                                colorScheme="blue"
                                defaultChecked={values.published}
                                onChange={() => {
                                  form.setFieldValue(
                                    'published',
                                    !values.published
                                  )
                                }}
                              >
                                公開する
                              </Checkbox>
                            </FormControl>
                          )}
                        </Field>
                        <HStack
                          direction="row"
                          spacing={4}
                          justifyContent="center"
                        >
                          <Button
                            variant="basic-btn"
                            onClick={() => {
                              navigate('/v2/enquetes')
                            }}
                          >
                            キャンセル
                          </Button>
                          <Button
                            type="submit"
                            m="auto"
                            variant="primary-btn"
                            isLoading={isSubmitting}
                            disabled={
                              (answerPeriodFlg && deadlineFlg) ||
                              invalidFlg ||
                              !isValid
                            }
                          >
                            {submitButtonLabel}
                          </Button>
                        </HStack>
                      </Stack>
                    </Stack>
                  </Container>
                </Form>
              </Box>
            </Flex>
            <EditPreviewLayout>
              <LivePreviewEnquete
                handleOpenPreviewModal={pmDisclosure.onOpen}
              />
            </EditPreviewLayout>
            <LivePreviewModal
              pmIsOpen={pmDisclosure.isOpen}
              onClosePm={pmDisclosure.onClose}
            >
              <LivePreviewEnquete isModal={true} />
            </LivePreviewModal>
          </>
        )
      }}
    </Formik>
  )
}
