import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from '@chakra-ui/react'
import { EmbedAnswerEnquete } from 'pages/EmbedAnswerEnquete'
import { PlayQuiz } from 'components/PlayQuiz'
import { PlayQuizPlaylistPreview } from 'v2pages/PlayQuizPlaylistPreview'

type Props = {
  pmIsOpen: boolean
  onClosePm: () => void
  itemId: string
  contentType: string
}

export const PreviewModal: React.FC<Props> = ({
  pmIsOpen,
  onClosePm,
  itemId,
  contentType,
}) => {
  return (
    <>
      <Modal isOpen={pmIsOpen} onClose={onClosePm}>
        <ModalOverlay />
        <ModalContent
          py="60px"
          px="24px"
          maxW="800px"
          maxH="90vh"
          overflowY="scroll"
        >
          <ModalCloseButton w="40px" h="40px" />
          {contentType === 'enquete' && (
            <EmbedAnswerEnquete enqueteId={itemId} isPreview={true} />
          )}
          {contentType === 'quiz' && (
            <PlayQuiz quizId={itemId} isPreview={true} isEmbed={true} />
          )}
          {contentType === 'quizlist' && (
            <PlayQuizPlaylistPreview quizPlaylistId={itemId} isEmbed={true} />
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
