import React, { useRef } from 'react'
import { Box, Image, VStack } from '@chakra-ui/react'

export interface LayoutEmbedQuizProps {
  imageUrl?: string
  alt: string
  quizListId?: string
}

export const LayoutEmbedQuizPlaylist: React.FC<LayoutEmbedQuizProps> = ({
  children,
  imageUrl = '',
  alt,
  quizListId,
}) => {
  const itemId = quizListId ? quizListId : ''
  const elm = useRef<HTMLInputElement>(null)
  const setEmbedWindowHeight = (quizListId: string) => {
    if (!elm.current) {
      return
    }
    const { clientHeight } = elm.current
    window.parent.postMessage([clientHeight, quizListId], '*')
  }

  let isPlayPage = false
  const url = window.location.href // 現在のページURLを取得
  if (url.indexOf('/embed/play') > -1) {
    isPlayPage = true
  }

  return (
    <Box
      w="100%"
      h="100%"
      position="relative"
      borderRadius="16px"
      overflow="hidden"
      onLoad={() => setEmbedWindowHeight(itemId)}
      ref={elm}
    >
      <Box
        w="100%"
        minH={isPlayPage ? '100vh' : '0'}
        position="relative"
        borderRadius="16px"
        overflow="hidden"
      >
        <Image
          position="absolute"
          w="100%"
          h="100%"
          objectFit="cover"
          src={imageUrl || '/images/whiteImage.png'}
          alt={alt}
          filter="brightness(65%)"
        />
        <VStack
          w="100%"
          h="100%"
          minHeight="480px"
          justify="center"
          position="relative"
          color="white"
          p="15px 30px"
          fontSize={{ base: 'md', sm: 'lg' }}
        >
          {children}
        </VStack>
      </Box>
    </Box>
  )
}
